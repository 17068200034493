<template>
    <div>
        <el-dialog :title="title" :visible.sync="open" width="700px" @close="commitOnClose">
            <el-form ref="saveOrEditForm" :model="saveData" label-width="150px" style="width:600px" :rules="rules">
                <el-form-item label="商户编号" prop="merchantNo">
                    <el-input v-model="saveData.merchantNo" placeholder="请输入商户编号" @keyup.enter.native="getMerchantName"
                        @blur="getMerchantName" />
                </el-form-item>
                <el-form-item label="商户名称">
                    {{ merchantName }}
                </el-form-item>
                <el-form-item label="加收类型" prop="adjustNo">
                    <el-select v-model="saveData.adjustNo" placeholder="请选择加收类型" clearable size="small"
                        @change="adjustNoChange" :disabled="!isAdd" style="width: 280px">
                        <el-option v-for="(value, key) in dicts.ADJUST_NO" :label="value" :value="key" :key="key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="调价类型" prop="adjustType">
                    {{ dicts.ADJUST_TYPE[saveData.adjustType] }}
                    <!-- <el-select v-model="saveData.adjustType" placeholder="请选择调价类型" clearable size="small" @change="getRate"
                        :disabled="!isAdd" style="width: 280px">
                        <el-option v-for="(value, key) in dicts.ADJUST_TYPE" :label="value" :value="key" :key="key">
                        </el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="调价比例" prop="rate">
                    <el-select v-model="saveData.rate" placeholder="请选择调价比例" style="width: 280px">
                        <el-option v-for="item in rateList" :label="item.label" :value="item.value" :key="item.value">
                        </el-option>
                    </el-select> <span v-if="saveData.adjustNo == 'B'">元</span><span v-else>%</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitCheck">确定新增</el-button>
                <el-button type="primary" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { FinancialToolsApi, TerminalApi, SpecialApi } from '@/api';
export default {
    name: "CustomForm",
    props: {
        dicts: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isAdd: {
            type: Boolean,
            default: true,
        },
        selectRow: {
            type: Object,
            default: function () {
                return {}
            },
        },
        onFresh: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            title: '',
            saveData: {},
            open: false,
            rules: {
                merchantNo: [{ required: true, message: '请输入商户编号', trigger: 'change' }],
                adjustType: [{ required: true, message: '请选择调价类型', trigger: 'change' }],
                adjustNo: [{ required: true, message: '请选择加价类型', trigger: 'change' }],
                rate: [{ required: true, message: '请选择调价比例', trigger: 'change' }],
            },
            options: [],
            agentNo: '',
            loading: false,
            rateList: [],
            merchantName: ''
        }
    },
    watch: {
        onFresh: function (value) {
            this.open = value;
            if (value) {
                this.resetData();
            }
        }
    },
    mounted() {
    },
    methods: {
        async remoteMethod(query) {
            if (query != '') {
                let res = await FinancialToolsApi.suspendOrder.queryAgentInfo(query)
                if (res.success) {
                    this.options = res.data
                    console.log(res.data)
                }
            } else {
                this.options = []
            }
        },
        submitCheck() {
            if (!this.merchantName) {
                this.Message.error('请输入正确的商户编号')
                return
            }
            this.$refs.saveOrEditForm.validate((valid) => {
                if (valid) {
                    this.submitForm()
                } else {
                    return false;
                }
            });
        },
        async resetData() {
            if (this.isAdd) {
                this.title = "新增";
                this.saveData = {};
                this.merchantName = '';
            }
        },
        async submitForm() {
            let result = (this.isAdd) ?
                (await SpecialApi.merchantAdjustRate.save(this.saveData)) :
                (await SpecialApi.merchantAdjustRate.edit(this.saveData.id, this.saveData));
            if (result.success) {
                this.Message.success(this.isAdd ? '添加成功' : '修改成功');
                this.cancel()
            }
        },
        cancel() {
            this.saveData = {};
            this.saveData.userName = ''
            this.saveData.agentNo = ''
            this.saveData.adjustNo = ''
            this.saveData.rate = ''
            this.saveData.agentNo2 = ''
            this.commitOnClose();
        },
        commitOnClose() {
            this.$emit('on-close');
        },
        getUserName(item) {
            this.saveData.userName = item.userName
            this.saveData.agentNo = item.agentNo
            this.saveData.agentNo2 = item.agentNo
        },
        getRate(val) {
            console.log(val)
            this.rateList = []
            if (this.isAdd) {
                this.$set(this.saveData, 'rate', '')
            }
            if (val == 'TRANS') {
                for (let i = 0; i <= 6; i++) {
                    this.rateList.push({ value: i / 100, label: i / 100 })
                }
            }
            if (val == 'SETTLE') {
                for (let i = 0; i <= 3; i++) {
                    this.rateList.push({ value: i, label: i })
                }
            }

        },
        async getMerchantName() {
            let res = await TerminalApi.terminalInfo.queryMerchantName(
                this.saveData.merchantNo
            )
            if (res.success) {
                this.merchantName = res.data.merchantName
                this.saveData.merchantName = res.data.merchantName
            } else {
                this.merchantName = ''
                this.saveData.merchantName = ''
                this.Message.error(res.message)
            }
        },
        adjustNoChange(val) {
            this.rateList = []
            if (val == 'B') {
                this.saveData.adjustType = 'SETTLE'
                for (let i = 0; i <= 3; i++) {
                    this.rateList.push({ value: i, label: i })
                }
            } else {
                this.saveData.adjustType = 'TRANS'
                if (val == 'C') {
                    for (let i = 53; i <= 100; i++) {
                        this.rateList.push({ value: i / 100, label: i / 100 })
                    }
                } else {
                    for (let i = 0; i <= 6; i++) {
                        this.rateList.push({ value: i / 100, label: i / 100 })
                    }
                }

            }
        }
    }
};
</script>

<style scoped></style>
